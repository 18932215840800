<template>
  <div class="header">
    <div class="header-container">
      <img class="logo" src="../assets/img/home/happy-logo.png" alt="logo" />
      <div class="header-btns">
        <div
          class="btns-item"
          :class="{ 'btns-item-select': item.select }"
          v-for="(item, index) in headerBtns"
          :key="index"
          @click="clickHeaderBtn(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-if="userInfo" class="header-right">
        <el-button @click="pwdForm.visible = true" type="text"
          >修改密码</el-button
        >
      </div>
      <div v-else class="header-right">
        <el-button @click="toLogin" type="text">登录</el-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="pwdForm.visible"
      title="修改密码"
      append-to-body
      width="400px"
      :close-on-click-modal="false"
    >
      <el-form label-width="80px" size="small">
        <el-form-item label="新密码" required>
          <el-input
            placeholder="请输入新密码"
            v-model="pwdForm.form.password"
            :maxlength="20"
          />
        </el-form-item>
        <el-form-item label="确认密码" required>
          <el-input
            placeholder="请输入确认密码"
            v-model="pwdForm.form.newPassword"
            :maxlength="20"
          />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button size="small" @click="pwdForm.visible = false"
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="setPwd">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "NavHeader",
  props: {
    btnId: {
      type: String,
      default: "course",
    },
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      headerBtns: [
        {
          id: "home",
          name: "首页",
          select: false,
        },
        {
          id: "course",
          name: "课程",
          select: false,
        },
        {
          id: "test",
          name: "题库",
          select: false,
        },
        {
          id: "download",
          name: "资料下载",
          select: false,
        },
      ],
      pwdForm: {
        visible: false,
        form: {
          password: "",
          newPassword: "",
        },
      },
    };
  },
  watch: {
    btnId: {
      handler(val) {
        for (let i = 0; i < this.headerBtns.length; i++) {
          if (this.headerBtns[i].id === val) {
            this.headerBtns[i].select = true;
          } else {
            this.headerBtns[i].select = false;
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    clickHeaderBtn(id) {
      if (id === this.btnId) {
        return;
      }
      if (id === "home") {
        this.$router.push("/");
      } else if (id === "course") {
        this.$router.push("/courseList");
      } else if (id === "test") {
        this.$router.push("/questionBank");
      } else if (id === "download") {
        this.$router.push("/download");
      }
    },
    toLogin() {
      this.$router.push("/login");
    },
    setPwd() {
      if (!this.pwdForm.form.password) {
        this.$message.error("请输入新密码");
        return;
      }
      if (this.pwdForm.form.password !== this.pwdForm.form.newPassword) {
        this.$message.error("两次密码输入不一致");
        return;
      }
      let loginData = { ...this.pwdForm.form };
      this.axios.post("/setPwd", loginData).then(() => {
        this.$message.success("密码修改成功");
        this.pwdForm.visible = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(47, 51, 56, 0.08);
  display: flex;
  justify-content: center;
  .header-container {
    position: relative;
    width: 1180px;
    height: 60px;
    display: flex;
    align-items: center;
    .logo {
      width: auto;
      height: 60px;
      margin-right: 60px;
    }
    .header-btns {
      display: flex;
      align-items: center;
      .btns-item {
        font-size: 18px;
        color: #3c464f;
        font-weight: 700;
        letter-spacing: normal;
        font-family: Microsoft YaHei, Microsoft JhengHei !important;
        margin-right: 40px;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        &:hover {
          color: #1cb877;
        }
      }
      .btns-item-select {
        color: #1cb877;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 3px;
          background-color: #1cb877;
        }
      }
    }
    .header-right {
      position: absolute;
      right: 0;
      top: 0;
      height: 60px;
      display: flex;
      align-items: center;
    }
  }
}
@media screen and (max-width: 900px) {
  .header {
    width: 100%;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgba(47, 51, 56, 0.08);
    display: flex;
    justify-content: center;
    .header-container {
      position: relative;
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      .logo {
        width: auto;
        height: 60px;
        margin-right: 0;
      }
      .header-btns {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-around;
        .btns-item {
          font-size: 15px;
          color: #3c464f;
          font-weight: 700;
          letter-spacing: normal;
          font-family: Microsoft YaHei, Microsoft JhengHei !important;
          margin-right: 0;
          height: 60px;
          line-height: 60px;
          cursor: pointer;
          &:hover {
            color: #1cb877;
          }
        }
        .btns-item-select {
          color: #1cb877;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background-color: #1cb877;
          }
        }
      }
      .header-right {
        display: none;
      }
    }
  }
}
</style>